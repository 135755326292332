<template>
    <div>
        <v-container class="live mx-auto">

            <div class="titles">
                <p class="tl pt-1">{{ $t("alkafeel_title") }}</p>
                <p class="tl pt-1">{{ $t("live") }}</p>
            </div>
            <v-layout row mt-8>
                <v-flex sm12 style="position: relative;" :class="yt ? 'md8' : 'md9'">
                    <template v-if="playerLoading">
                        <v-skeleton-loader v-bind="attrs" style="width: 100%;" class="mb-6" type="text@1"></v-skeleton-loader>
                    </template>
                    <template v-else>
                        <!-- <v-layout row mt-1 >
                            <v-flex md12 mx-4> -->
                                <div class="live_ico"><svg viewBox="0 0 23 15"><use xlink:href="/assets/img/services/live.svg#view"></use></svg></div>
                                <p class="dist_title t1">{{$t('live_view')}} : {{liveDist}}</p>
                            <!-- </v-flex>
                            <v-flex md4 px-4 style="text-align: center" :style="$vuetify.rtl ? 'text-align: left' : 'text-align: right'">
                                <p v-if="yt" class="dist_stat messiri_fonted" >{{statistics}} {{$t('views')}}</p>
                            </v-flex>
                        </v-layout> -->
                    </template>



                    <div class="player_wrap mt-2 px-4">
                        <v-skeleton-loader v-if="playerLoading" style="height:100%" v-bind="attrs" type="image" ></v-skeleton-loader>
                        <video v-show="!playerLoading" ref="videoPlayer" class="video-js vjs-theme-dt"></video>
                    </div>

                    <template v-if="playerLoading">
                        <v-layout row>
                            <v-flex pa-3 sm4 class="comments"><v-skeleton-loader v-bind="attrs" type="image"></v-skeleton-loader></v-flex>
                            <v-flex pa-3 sm4 class="comments"><v-skeleton-loader v-bind="attrs" type="image"></v-skeleton-loader></v-flex>
                            <v-flex pa-3 sm4 class="comments"><v-skeleton-loader v-bind="attrs" type="image"></v-skeleton-loader></v-flex>
                        </v-layout>
                    </template>
                    
                    <template v-else>

                        <!-- <template v-if="yt" >
                            <v-flex sm12 mt-11 pb-10 px-4 class="comments hidden-md-and-up">
                                <iframe id="videoObject" :src="'https://www.youtube.com/live_chat?v='+yt_src+'&embed_domain='+baseDomain" type="text/html" width="100%" height="100%" frameborder="0"></iframe>
                            </v-flex>
                        </template> -->

                        <template v-if="yt">
                            <p class="dist_title t1 px-4">{{$t('live_cameras')}}</p>
                            <v-layout row px-4>
                                <v-flex pa-3 sm4 v-for="source in live" :key="source">
                                    <v-img @click="playSrc(source.src, source.pic, source.title, source.id)" :src="'https://alkafeel.net/live/images/resize/'+source.pic">
                                        <div class="play"><img :class="source.id" :src="activeId === source.id ? '/assets/img/pause_icon.svg' : '/assets/img/play_icon.svg'" /></div>
                                    </v-img>
                                    <p class="t1 text-center">{{source.title}}</p>
                                </v-flex>
                            </v-layout>
                        </template>

                    </template>
                </v-flex>

                <template v-if="playerLoading">
                    <v-flex sm4 mt-11 pb-10 px-4 class="comments">
                        <v-skeleton-loader style="height:100%" v-bind="attrs" type="paragraph@12"></v-skeleton-loader>
                    </v-flex>
                </template>

                <template v-else>

                    <template v-if="yt" >
                        <v-flex sm12 md4 mt-11 pb-10 px-4 class="comments hidden-sm-and-down">
                            <iframe id="videoObject" :src="'https://www.youtube.com/live_chat?v='+yt_src+'&embed_domain='+baseDomain" type="text/html" width="100%" height="100%" frameborder="0"></iframe>
                        </v-flex>
                    </template>
                    <template v-else>
                        <v-flex sm12 md3 px-4 class="comments" >

                            <p class="dist_title t1">{{$t('live_cameras')}}</p>
                            <v-layout row style="max-height:550px; overflow-y:auto">
                                <v-flex pa-3 md12 sm4 v-for="source in live" :key="source">
                                    <v-img @click="playSrc(source.src, source.pic, source.title, source.id)" max-height="124px" :src="'https://alkafeel.net/live/images/resize/'+source.pic">
                                        <div class="play"><img :class="source.id" :src="activeId === source.id ? '/assets/img/pause_icon.svg' : '/assets/img/play_icon.svg'" /></div>
                                    </v-img>
                                    <p class="t1 text-center">{{source.title}}</p>
                                </v-flex>
                            </v-layout>

                        </v-flex>
                    </template> 

                </template>
                
            </v-layout>
            
        </v-container>


        <v-container fluid>
            <v-layout row mt-8>
                <v-img :gradient="$vuetify.theme.dark ? 'to bottom, rgba(0,0,0,.7), rgba(0,0,0,.6)' : ''" src="/assets/img/live/zyara.jpg" :max-height="$vuetify.breakpoint.mdAndUp ? '300px' : ''">

                    <v-layout row class="live mx-auto" mt-10>
                        <p class="zyara_cat tl text-center" style="width:100%">{{ zyaraData.title }}</p>
                    </v-layout>

                    <v-form ref="form" v-model="valid">
                        <v-layout row mb-8 class="live mx-auto">
                            <v-flex sm5 pa-2>
                                <v-text-field v-model="zeara_i.name" solo flat color="success" :placeholder="$t('name')" hide-details="auto" :rules="[rules.required]"  ></v-text-field>
                            </v-flex>
                            <v-flex sm5 pa-2>
                                <v-text-field v-model="zeara_i.email" solo flat color="success" :placeholder="$t('msg_email_optional')" hide-details="auto" :rules="[rules.email]" ></v-text-field>
                            </v-flex>
                            <v-flex sm2 pa-2>
                                <v-btn min-height="40px" width="100%" max-height="50px" height="100%" class="tl white--text" :loading="loading" color="rgba(177, 189, 82, 1)" @click="zyara_send()"><p class="tl text-dark mb-0">{{ $t('action_send') }}</p></v-btn>
                            </v-flex>
                        </v-layout>
                    </v-form>
                    
                </v-img>
            </v-layout>
        </v-container>


        <v-container class="live mx-auto">
            <p class="zyara_title tx">{{$t('live_zyara_text')}}</p>
            <p id="zyara_load" class="tx">{{zyaraText}}</p>
        </v-container>

    </div>
</template>

    
    


<script>
// import axios from "axios";
import videojs from 'video.js';
import 'videojs-youtube';
import 'video.js/dist/video-js.min.css';
import '@digitaltheatre/videojs-theme-dt/dist/theme/index.css';
import Swal from "sweetalert2";
export default {
    name: 'VideoPlayer',
    data() {
        return {
            live:[],
            liveDist: '',
            activeId: 1,

            yt: false,
            yt_src: '',
            statistics: 0,

            // API_KEY: 'AIzaSyA5YrOTJcyYsEB-RORWb4wWMQzkzR0_sas',
            // API_KEY: 'AIzaSyBhU7kp_5L2gqhtfyL0iq21929ZYMzLe80',
            // channelId: 'UCFHL7Ku2fj2FcMW1n6ILrhw',
            
            // API_KEY: 'AIzaSyCrYE4UwetlufokfD320p9OtNdzfNdLMu8', //testing Channel
            // channelId: 'UCdPAWdtBZdf18kQ9X0cup3w',

            baseDomain: window.location.hostname,

            playerLoading: true,
            axiosLoad: true,
            attrs: {
                class: 'sm-4',
                boilerplate: false,
                elevation: 2,
            },


            zeara_i:{name:'', email:'', ab:'', save:'save'},
            zyaraData: [],
            zyaraText: '',

            loader: null,
            loading: false,

            player: null,
            videoOptions: {
				autoplay: 'muted',
				// autoplay: false,
                poster: '',
				controls: true,
                fill: true,
                controlBar: {
                    remainingTimeDisplay: false,
                    currentTimeDisplay: false,
                    timeDivider: false,
                    durationDisplay: true
                    // children: [
                    //     'playToggle',
                    //     'progressControl',
                    //     'volumePanel',
                    //     'fullscreenToggle',
                    // ],
                },
                autoSetup: false,
                techOrder: ['youtube', 'html5'],
                sources: [{ src: "https://stream.alkafeel.net/live/alkafeel/rAa5PGot1/manifest.m3u8", type: "application/x-mpegURL"}],
				// sources: [{ src: "https://www.youtube.com/watch?v="+this.yt_src, type: "video/youtube"}],
			},
            rules: {
                required: value => !!value || this.$t("required_field"),
                min: (v) => v.length >= 3 || this.$t("three_letters"),
                email: v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || this.$t("alert_wrong_email"),
            },
        }
    },
    watch: {
        loader () {
            const l = this.loader
            this[l] = !this[l]
            setTimeout(() => (this[l] = false), 3000)
            this.loader = null
        },
        axiosLoad(n, o) {
            if (!n && this.yt) {
                
                this.live_ini('yt');
                this.$refs.videoPlayer.load();
                setTimeout(() => this.loadPlayer(), 1000);
                setTimeout(() => this.playerLoading = false, 500);
            } else {
                
                this.live_ini('native');
                this.$refs.videoPlayer.load();
                setTimeout(() => this.loadPlayer(), 1000);
                setTimeout(() => this.playerLoading = false, 500);
            }
        }
    },
    components: {
    },
    directives: {
    },
    computed: {
    },
    beforeCreate() {
        document.title =this.$t("live_all"); 

        this.$axios.get("zyara/GetDetails")
        .then(d => {
            this.zyaraData = d.data.Data;
        });

        this.$axios.get("live/zyara")
        .then(d => {
            this.zyaraText = d.data[0].text;
        })
    },
    created () {

        // this.$axios.get("https://www.googleapis.com/youtube/v3/search?part=snippet&channelId="+this.channelId+"&type=video&eventType=live&key="+this.API_KEY)
        // this.$axios.get("https://alkafeel.net/main_bg/data/ytb_api.json?t="+ Date.now())
        // .catch(this.live_ini())
        // .then(d => {
        //     if (d) {
        //         if (d.status === 200 && d.data.pageInfo.totalResults > 0) {
        //             this.yt_src = d.data.items[0].id.videoId;
        //             this.$axios.get('//www.youtube.com/embed/'+this.yt_src+'?origin=https://'+this.baseDomain)
        //             .then((data) => {
        //                 if (data.status === 200 && data.data.charAt(data.data.indexOf('videoDurationSeconds')+25) === '0') {
        //                     this.yt = true;
        //                     this.axiosLoad = false
        //                 } else {
        //                     this.axiosLoad = false
        //                 }
        //             });
                    
        //         } else {
        //             this.axiosLoad = false
        //         }
        //     } else {
        //         this.axiosLoad = false
        //     }
            
        // });

        this.$axios.get("https://alkafeel.net/main_bg/data/live_proxy.php?t="+ Date.now())
        .then(d => {
            if (d.status === 200 && d.data.live) {
                this.yt_src = d.data.videoId;
                if (this.$route.query.native !== null) this.yt = true;
                this.axiosLoad = false
            } else {
                this.axiosLoad = false
            }
        });
    },
    mounted () {
        
        // this.$refs.videoPlayer.load();
        // setTimeout(() => this.loadPlayer(), 1000);
        // setTimeout(() => this.playerLoading = false, 500);
        

    }, 
    updated () {
        // this.player = videojs(this.$refs.videoPlayer, this.videoOptions);
    },
    beforeDestroy() {
        if (this.player) {
            this.player.dispose();
        }
    },
    methods:{

        live_ini(type) {

            this.$axios.get("live/all")
            .then((d) => {
                this.live = d.data;
                // console.log(type);
                if (type === 'yt') {
                    this.videoOptions.sources[0].src = '//www.youtube.com/watch?v='+this.yt_src; 
                    this.videoOptions.sources[0].type = 'video/youtube'
                } else {
                   this.videoOptions.sources[0].src = d.data[0]['src']; 
                   this.videoOptions.sources[0].type = 'application/x-mpegURL'
                }

                this.videoOptions.poster = 'https://alkafeel.net/live/images/'+d.data[0]['pic'];
                this.liveDist = d.data[0]['title'];
                this.activeId = d.data[0]['id'];

            });
        },
        playSrc(src, pic, title, id) {
            this.player.poster('https://alkafeel.net/live/images/'+pic);
            this.player.src({ type: "application/x-mpegURL", src: src });
            this.liveDist = title;
            this.activeId = id;
            this.player.play();

            // scroll to pos
            window.scrollTo({ top: 140, behavior: 'smooth'});
            // let rect = this.$el.querySelector('.dist_stat').getBoundingClientRect()
            // window.scrollTo(rect.left, rect.top)
        },
        yt_src_fn() {
            this.$axios.get("https://www.googleapis.com/youtube/v3/search?part=snippet&channelId="+this.channelId+"&type=video&eventType=live&key="+this.API_KEY)
            .then(d => {
                if (d.status === 200 && d.data.pageInfo.totalResults > 0) {
                    let a = d.data.items[0].id.videoId;
                    this.yt = true;
                    this.yt_src = a;
                }
            });
        },
        getStatistics () {
            this.$axios.get("https://www.googleapis.com/youtube/v3/videos?id="+this.yt_src+"&part=statistics&key="+this.API_KEY)
            .then(d => {
                if (d.status === 200 && d.data.pageInfo.totalResults > 0) {
                    let a = d.data.items[0].statistics.viewCount;
                    this.statistics = a;
                }
            });
        },
        loadPlayer() {
            this.player = videojs(this.$refs.videoPlayer, this.videoOptions);
        },
        zyara_send() {
            if (this.$refs.form.validate()) {
                var formdata = new FormData();
                formdata.append("name",  this.zeara_i.name);
                formdata.append("ab",  this.zeara_i.ab);
                formdata.append("save", "save");
                formdata.append("email", this.zeara_i.email);
                this.loader = 'loading';
               this.$axios({method: 'post',url: 'https://alkafeel.net/zyara/Avdasdw/1352897989/post',data: formdata,
               }).then(res => {
                res
                    this.zeara_i = {};
                    Swal.fire({
                       

                            position: 'center',
                            icon: 'success',
                            title: this.$t("zeara_result"),
                            showConfirmButton: false,
                            timer: 2000
                    });
                    //console.log(res.data);
                }).catch(() => {
                });
            }
        },
    
    }
}
</script>

<style scoped>
#videoObject {
    min-height: 700px;
    border-radius: 5px;
    border: solid 2px #D4D4D4;
}
.live {
  width: 100%;
  padding-top: 30px;
  max-width: 1260px;
}
.live .titles {
    text-align: center;
}
.live .titles p:nth-child(1) {
  color: #ACA8A8;
  font-size: 15px;
}

.live .titles p:nth-child(2) {
  color: #000;
  font-size: 35px;
  font-family: 'cairo_bold';
}
.player_wrap {
    width: 100% !important;
    height: 500px !important;
    margin-bottom: 25px;
}
@media (max-width: 600px) {
    .player_wrap {
        height: 300px !important;
    }
    .dist_title, .dist_stat {
        font-size: 14px!important;
    }
    .live .titles p:nth-child(2) {
        font-size: 22px;
    }
    .live .titles p:nth-child(1) {
        font-size: 10px;
    }
    .zyara_cat {
        font-size: 18px!important;
    }
}
@media (max-width: 420px) {
    .player_wrap {
        height: 200px !important;
    }
}
.play {
    position: absolute;
    top: calc(50% - 32px);
    right: calc(50% - 32px);
    width: 64px
}
.comments {
    padding: 0 10px;
}
.dist_title {
    font-size: 20px;
    font-family: 'cairo_bold';
    display: inline-block;
}
.dist_stat {
    /* display: inline-block;
    position: absolute; */
    /* left: 0; */
    padding: 0 15px;
    font-weight: 700;
    font-size: 18px;
    color: rgba(108, 108, 108, 0.6)
}
.zyara_cat {
    font-size: 32px;
    font-family: 'cairo_bold';
}
.live_ico {
    /* fill:#F00; */
    width: 25px;
    height: 25px;
    margin: 0 10px 5px;
    display: inline-block;
}
@keyframes changeColor {
    from {
        fill: #FFF;
    }
    to {
        fill: #F00;
    }
}
.live_ico {
    animation: changeColor 1s infinite alternate;
}
.zyara_title {
	color:#43484a;
	background:#faf8bb;
	display:inline-block;
	padding:5px 15px;
	margin-top:36px;
	font-size:18px;
	margin-bottom:10px;
}
#zyara_load {
	color:#43484a;
	background:#fcfbdd;
	font-size:18px;
	padding:15px;
	text-align:justify;
	margin-bottom:25px;
}
.vjs-control-bar {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%) !important;
}
.vjs-theme-dt {
    --color-primary:#B1BD52 !important;
}
</style>